@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?kadrpj');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?kadrpj#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?kadrpj') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?kadrpj') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?kadrpj##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='bitso-'],
[class*=' bitso-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bitso-usdt {
  &:before {
    content: $bitso-usdt;
  }
}
.bitso-case {
  &:before {
    content: $bitso-case;
  }
}
.bitso-clip {
  &:before {
    content: $bitso-clip;
  }
}
.bitso-exit-analysis {
  &:before {
    content: $bitso-exit-analysis;
  }
}
.bitso-exit-fullscreen {
  &:before {
    content: $bitso-exit-fullscreen;
  }
}
.bitso-full-screen {
  &:before {
    content: $bitso-full-screen;
  }
}
.bitso-technical-analysis {
  &:before {
    content: $bitso-technical-analysis;
  }
}
.bitso-bitso {
  &:before {
    content: $bitso-bitso;
  }
}
.bitso-busd {
  &:before {
    content: $bitso-busd;
  }
}
.bitso-pax {
  &:before {
    content: $bitso-pax;
  }
}
.bitso-usdc {
  &:before {
    content: $bitso-usdc;
  }
}
.bitso-spinner {
  &:before {
    content: $bitso-spinner;
  }
}
.bitso-list_open {
  &:before {
    content: $bitso-list_open;
  }
}
.bitso-list_close {
  &:before {
    content: $bitso-list_close;
  }
}
.bitso-instagram {
  &:before {
    content: $bitso-instagram;
  }
}
.bitso-telegram {
  &:before {
    content: $bitso-telegram;
  }
}
.bitso-youtube {
  &:before {
    content: $bitso-youtube;
  }
}
.bitso-linkedin {
  &:before {
    content: $bitso-linkedin;
  }
}
.bitso-tech_gear {
  &:before {
    content: $bitso-tech_gear;
  }
}
.bitso-tech_arrowdown {
  &:before {
    content: $bitso-tech_arrowdown;
  }
}
.bitso-tech_arrowup {
  &:before {
    content: $bitso-tech_arrowup;
  }
}
.bitso-tech_close {
  &:before {
    content: $bitso-tech_close;
  }
}
.bitso-dai {
  &:before {
    content: $bitso-dai;
  }
}
.bitso-close {
  &:before {
    content: $bitso-close;
  }
}
.bitso-hamburger-menu {
  &:before {
    content: $bitso-hamburger-menu;
  }
}
.bitso-notif-trade {
  &:before {
    content: $bitso-notif-trade;
  }
}
.bitso-notif-fund {
  &:before {
    content: $bitso-notif-fund;
  }
}
.bitso-arrow-send {
  &:before {
    content: $bitso-arrow-send;
  }
}
.bitso-notif-sell {
  &:before {
    content: $bitso-notif-sell;
  }
}
.bitso-notif-buy {
  &:before {
    content: $bitso-notif-buy;
  }
}
.bitso-order-partial {
  &:before {
    content: $bitso-order-partial;
  }
}
.bitso-order-open {
  &:before {
    content: $bitso-order-open;
  }
}
.bitso-paperwork {
  &:before {
    content: $bitso-paperwork;
  }
}
.bitso-world {
  &:before {
    content: $bitso-world;
  }
}
.bitso-transfer {
  &:before {
    content: $bitso-transfer;
  }
}
.bitso-zrx {
  &:before {
    content: $bitso-zrx;
  }
}
.bitso-eye {
  &:before {
    content: $bitso-eye;
  }
}
.bitso-mana {
  &:before {
    content: $bitso-mana;
  }
}
.bitso-bat {
  &:before {
    content: $bitso-bat;
  }
}
.bitso-no-camera {
  &:before {
    content: $bitso-no-camera;
  }
}
.bitso-chevron-right {
  &:before {
    content: $bitso-chevron-right;
  }
}
.bitso-warning {
  &:before {
    content: $bitso-warning;
  }
}
.bitso-check {
  &:before {
    content: $bitso-check;
  }
}
.bitso-tusd {
  &:before {
    content: $bitso-tusd;
  }
}
.bitso-gnt {
  &:before {
    content: $bitso-gnt;
  }
}
.bitso-pesos {
  &:before {
    content: $bitso-pesos;
  }
}
.bitso-ltc {
  &:before {
    content: $bitso-ltc;
  }
}
.bitso-bch {
  &:before {
    content: $bitso-bch;
  }
}
.bitso-xrp {
  &:before {
    content: $bitso-xrp;
  }
}
.bitso-eth {
  &:before {
    content: $bitso-eth;
  }
}
.bitso-btc {
  &:before {
    content: $bitso-btc;
  }
}
.bitso-bank {
  &:before {
    content: $bitso-bank;
  }
}
.bitso-cash {
  &:before {
    content: $bitso-cash;
  }
}
.bitso-mobile {
  &:before {
    content: $bitso-mobile;
  }
}
.bitso-global {
  &:before {
    content: $bitso-global;
  }
}
.bitso-sad-face {
  &:before {
    content: $bitso-sad-face;
  }
}
.bitso-mail {
  &:before {
    content: $bitso-mail;
  }
}
.bitso-i_comment {
  &:before {
    content: $bitso-i_comment;
  }
}
.bitso-i_heart {
  &:before {
    content: $bitso-i_heart;
  }
}
.bitso-i_elliotw {
  &:before {
    content: $bitso-i_elliotw;
  }
}
.bitso-i_fibb {
  &:before {
    content: $bitso-i_fibb;
  }
}
.bitso-i_hodl {
  &:before {
    content: $bitso-i_hodl;
  }
}
.bitso-download-img {
  &:before {
    content: $bitso-download-img;
  }
}
.bitso-undo {
  &:before {
    content: $bitso-undo;
  }
}
.bitso-redo {
  &:before {
    content: $bitso-redo;
  }
}
.bitso-cursor {
  &:before {
    content: $bitso-cursor;
  }
}
.bitso-annotation {
  &:before {
    content: $bitso-annotation;
  }
}
.bitso-arc-speed-resistence {
  &:before {
    content: $bitso-arc-speed-resistence;
  }
}
.bitso-arrow {
  &:before {
    content: $bitso-arrow;
  }
}
.bitso-callout {
  &:before {
    content: $bitso-callout;
  }
}
.bitso-crosshair {
  &:before {
    content: $bitso-crosshair;
  }
}
.bitso-elipse {
  &:before {
    content: $bitso-elipse;
  }
}
.bitso-fib-arc {
  &:before {
    content: $bitso-fib-arc;
  }
}
.bitso-fib-fan {
  &:before {
    content: $bitso-fib-fan;
  }
}
.bitso-fib-projection {
  &:before {
    content: $bitso-fib-projection;
  }
}
.bitso-fib-retracement {
  &:before {
    content: $bitso-fib-retracement;
  }
}
.bitso-fib-timeline {
  &:before {
    content: $bitso-fib-timeline;
  }
}
.bitso-focus {
  &:before {
    content: $bitso-focus;
  }
}
.bitso-gann-fan {
  &:before {
    content: $bitso-gann-fan;
  }
}
.bitso-gartley-pattern {
  &:before {
    content: $bitso-gartley-pattern;
  }
}
.bitso-hide {
  &:before {
    content: $bitso-hide;
  }
}
.bitso-line-average {
  &:before {
    content: $bitso-line-average;
  }
}
.bitso-line-channel {
  &:before {
    content: $bitso-line-channel;
  }
}
.bitso-line-continuous {
  &:before {
    content: $bitso-line-continuous;
  }
}
.bitso-line-crossline {
  &:before {
    content: $bitso-line-crossline;
  }
}
.bitso-line-doodle {
  &:before {
    content: $bitso-line-doodle;
  }
}
.bitso-line-horizontal {
  &:before {
    content: $bitso-line-horizontal;
  }
}
.bitso-line-ray {
  &:before {
    content: $bitso-line-ray;
  }
}
.bitso-line-regression {
  &:before {
    content: $bitso-line-regression;
  }
}
.bitso-line-simple {
  &:before {
    content: $bitso-line-simple;
  }
}
.bitso-line-speed-resistence {
  &:before {
    content: $bitso-line-speed-resistence;
  }
}
.bitso-line-vertical {
  &:before {
    content: $bitso-line-vertical;
  }
}
.bitso-measure {
  &:before {
    content: $bitso-measure;
  }
}
.bitso-pitchfork {
  &:before {
    content: $bitso-pitchfork;
  }
}
.bitso-quadrant-lines {
  &:before {
    content: $bitso-quadrant-lines;
  }
}
.bitso-rectangle {
  &:before {
    content: $bitso-rectangle;
  }
}
.bitso-share {
  &:before {
    content: $bitso-share;
  }
}
.bitso-show {
  &:before {
    content: $bitso-show;
  }
}
.bitso-tirone-levels {
  &:before {
    content: $bitso-tirone-levels;
  }
}
.bitso-trash {
  &:before {
    content: $bitso-trash;
  }
}
.bitso-download {
  &:before {
    content: $bitso-download;
  }
}
.bitso-facebook {
  &:before {
    content: $bitso-facebook;
  }
}
.bitso-twitter {
  &:before {
    content: $bitso-twitter;
  }
}
