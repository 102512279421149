$fontsFolder: './fonts';

@import './glyphs.scss';

@font-face {
  font-family: 'Din Next LT Pro';
  src: url('#{$fontsFolder}/dinnextltpro-ultralight-webfont.eot');
  src: url('#{$fontsFolder}/dinnextltpro-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsFolder}/dinnextltpro-ultralight-webfont.woff') format('woff'),
    url('#{$fontsFolder}/dinnextltpro-ultralight-webfont.ttf') format('truetype'),
    url('#{$fontsFolder}/dinnextltpro-ultralight-webfont.svg#din_next_lt_proultralight') format('svg');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Din Next LT Pro';
  src: url('#{$fontsFolder}/dinnextltpro-light-webfont.eot');
  src: url('#{$fontsFolder}/dinnextltpro-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsFolder}/dinnextltpro-light-webfont.woff') format('woff'),
    url('#{$fontsFolder}/dinnextltpro-light-webfont.ttf') format('truetype'),
    url('#{$fontsFolder}/dinnextltpro-light-webfont.svg#din_next_lt_prolight') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Din Next LT Pro';
  src: url('#{$fontsFolder}/dinnextltpro-regular-webfont.eot');
  src: url('#{$fontsFolder}/dinnextltpro-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsFolder}/dinnextltpro-regular-webfont.woff') format('woff'),
    url('#{$fontsFolder}/dinnextltpro-regular-webfont.ttf') format('truetype'),
    url('#{$fontsFolder}/dinnextltpro-regular-webfont.svg#din_next_lt_proregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Din Next LT Pro';
  src: url('#{$fontsFolder}/DINProMedium.eot');
  src: url('#{$fontsFolder}/DINProMedium.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsFolder}/DINProMedium.woff') format('woff'), url('#{$fontsFolder}/DINProMedium.ttf') format('truetype'),
    url('#{$fontsFolder}/DINProMedium.svg#din_next_lt_proregular') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Din Next LT Pro';
  src: url('#{$fontsFolder}/dinnextltpro-bold-webfont.eot');
  src: url('#{$fontsFolder}/dinnextltpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontsFolder}/dinnextltpro-bold-webfont.woff') format('woff'),
    url('#{$fontsFolder}/dinnextltpro-bold-webfont.ttf') format('truetype'),
    url('#{$fontsFolder}/dinnextltpro-bold-webfont.svg#din_next_lt_probold') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fontsFolder}/ProximaNova-Light.eot');
  src: url('#{$fontsFolder}/ProximaNova-Light.woff2') format('woff2'),
    url('#{$fontsFolder}/ProximaNova-Light.woff') format('woff'),
    url('#{$fontsFolder}/ProximaNova-Light.ttf') format('truetype'),
    url('#{$fontsFolder}/ProximaNova-Light.svg#ProximaNova-Light') format('svg'),
    url('#{$fontsFolder}/ProximaNova-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fontsFolder}/ProximaNova-Regular.eot');
  src: url('#{$fontsFolder}/ProximaNova-Regular.woff2') format('woff2'),
    url('#{$fontsFolder}/ProximaNova-Regular.woff') format('woff'),
    url('#{$fontsFolder}/ProximaNova-Regular.ttf') format('truetype'),
    url('#{$fontsFolder}/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg'),
    url('#{$fontsFolder}/ProximaNova-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fontsFolder}/ProximaNova-RegularIt.eot');
  src: url('#{$fontsFolder}/ProximaNova-RegularIt.woff2') format('woff2'),
    url('#{$fontsFolder}/ProximaNova-RegularIt.woff') format('woff'),
    url('#{$fontsFolder}/ProximaNova-RegularIt.ttf') format('truetype'),
    url('#{$fontsFolder}/ProximaNova-RegularIt.svg#ProximaNova-RegularIt') format('svg'),
    url('#{$fontsFolder}/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fontsFolder}/ProximaNova-Semibold.eot');
  src: url('#{$fontsFolder}/ProximaNova-Semibold.woff2') format('woff2'),
    url('#{$fontsFolder}/ProximaNova-Semibold.woff') format('woff'),
    url('#{$fontsFolder}/ProximaNova-Semibold.ttf') format('truetype'),
    url('#{$fontsFolder}/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg'),
    url('#{$fontsFolder}/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('#{$fontsFolder}/ProximaNova-Bold.eot');
  src: url('#{$fontsFolder}/ProximaNova-Bold.woff2') format('woff2'),
    url('#{$fontsFolder}/ProximaNova-Bold.woff') format('woff'),
    url('#{$fontsFolder}/ProximaNova-Bold.ttf') format('truetype'),
    url('#{$fontsFolder}/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg'),
    url('#{$fontsFolder}/ProximaNova-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fontsFolder}/OpenSans-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fontsFolder}/OpenSans-Italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fontsFolder}/OpenSans-Light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fontsFolder}/OpenSans-LightItalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fontsFolder}/OpenSans-Semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fontsFolder}/OpenSans-SemiboldItalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-thin-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

// Bitso
@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-thin-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-thinitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-thinitalic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-light-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-lightitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-regular-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-regularitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-regularitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-bold-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-bolditalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-black-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso';
  src: url('#{$fontsFolder}/bitso-blackitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitso-blackitalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

// Bitso Alternate
@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-thin-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-thinitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-thinitalic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-light-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-lightitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-regular-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-regularitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-regularitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-bold-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-bolditalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-black-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bitso-Alternate';
  src: url('#{$fontsFolder}/bitsoalternate-blackitalic-webfont.woff2') format('woff2'),
    url('#{$fontsFolder}/bitsoalternate-blackitalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');

$primary: #4ebc42;
$font-family-base: 'Bitso', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: normal;

/**
 * START - custom styles for ChartIQ
 */
.ciq-chart,
.chartContainer {
  background-color: #0b0d0e;
}
.stx-float-date {
  background-color: #0b0d0e;
  border: 1px solid #262e39;
}
.stx_crosshair {
  opacity: 0.5;
}
.stx_crosshair.stx_crosshair_y {
  background: repeating-linear-gradient(-90deg, #b0bac1, #b0bac1 4px, transparent 4px, transparent 8px) top left;
}
.stx_crosshair.stx_crosshair_x {
  background: repeating-linear-gradient(0deg, #b0bac1, #b0bac1 4px, transparent 4px, transparent 8px) top left;
  background-position: 0 1px;
}

.chartSize {
  background-color: transparent;
}

.stx_range_slider.shading {
  background-color: rgba(22, 26, 30, 0.8);
  border: solid 0px #b0bac1;
  color: #747f89;
  width: 7px;
}

// Styles for chartIQ outliers
.compression,
.expansion {
  transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1) !important;
}

.outlier-sticker {
  z-index: 30 !important;
}

.outlier-sticker.axis.mini.high .pill,
.outlier-sticker.axis.mini.low .pill {
  top: 0px !important;
  background: #0a0d10 !important;
  border-radius: 9px;
  border: none;
  height: 32px;
  width: 20px;
}

.outlier-sticker.axis.mini.high .icon,
.outlier-sticker.axis.mini.low .icon {
  background-color: transparent !important;
}

.outlier-sticker.axis.mini.low:not(.compress) .expansion,
.outlier-sticker.axis.mini.low.compress .compression {
  height: 37px !important;
}

.outlier-sticker.quote.mini .pill {
  height: 20px !important;
  width: 12px !important;
}

.compression {
  box-shadow: none !important;
}

.Toastify__toast--default {
  margin: 0px !important;
  padding: 0px !important;
  background-color: transparent !important;
}

/**
 * END - custom styles for ChartIQ
 */
