$icomoon-font-family: 'bitsoglyphs' !default;
$icomoon-font-path: 'fonts' !default;

$bitso-linkedin1: '\e9c1';
$bitso-list_open: '\e956';
$bitso-list_close: '\e958';
$bitso-instagram: '\e952';
$bitso-telegram: '\e95a';
$bitso-youtube: '\e95c';
$bitso-tech_gear: '\e949';
$bitso-tech_arrowdown: '\e94b';
$bitso-tech_arrowup: '\e963';
$bitso-tech_close: '\e965';
$bitso-spinner: '\e9b5';
$bitso-usdc: '\e9b6';
$bitso-pax: '\e9b7';
$bitso-busd: '\e9b8';
$bitso-bitso: '\e9b9';
$bitso-technical-analysis: '\e9ba';
$bitso-full-screen: '\e9bb';
$bitso-exit-fullscreen: '\e9bc';
$bitso-exit-analysis: '\e9bd';
$bitso-clip: '\e9bf';
$bitso-case: '\e9be';
$bitso-usdt: '\e9c0';
$bitso-list_open1: '\e957';
$bitso-list_close1: '\e959';
$bitso-instagram1: '\e953';
$bitso-telegram1: '\e95b';
$bitso-youtube1: '\e95d';
$bitso-linkedin: '\e9b4';
$bitso-tech_gear1: '\e94a';
$bitso-tech_arrowdown1: '\e94c';
$bitso-tech_arrowup1: '\e964';
$bitso-tech_close1: '\e966';
$bitso-i_comment: '\e954';
$bitso-i_heart: '\e950';
$bitso-i_elliotw: '\e94d';
$bitso-i_fibb: '\e94f';
$bitso-i_hodl: '\e961';
$bitso-download-img: '\e948';
$bitso-undo: '\e943';
$bitso-redo: '\e96b';
$bitso-cursor: '\e93b';
$bitso-annotation: '\e919';
$bitso-arc-speed-resistence: '\e91b';
$bitso-arrow: '\e91d';
$bitso-callout: '\e91f';
$bitso-crosshair: '\e921';
$bitso-elipse: '\e923';
$bitso-fib-arc: '\e925';
$bitso-fib-fan: '\e927';
$bitso-fib-projection: '\e929';
$bitso-fib-retracement: '\e92b';
$bitso-fib-timeline: '\e92d';
$bitso-focus: '\e92f';
$bitso-gann-fan: '\e931';
$bitso-gartley-pattern: '\e933';
$bitso-hide: '\e935';
$bitso-line-average: '\e937';
$bitso-line-channel: '\e939';
$bitso-line-continuous: '\e975';
$bitso-line-crossline: '\e977';
$bitso-line-doodle: '\e979';
$bitso-line-horizontal: '\e97b';
$bitso-line-ray: '\e97d';
$bitso-line-regression: '\e97f';
$bitso-line-simple: '\e981';
$bitso-line-speed-resistence: '\e983';
$bitso-line-vertical: '\e985';
$bitso-measure: '\e987';
$bitso-pitchfork: '\e989';
$bitso-quadrant-lines: '\e98b';
$bitso-rectangle: '\e98d';
$bitso-share: '\e98f';
$bitso-show: '\e991';
$bitso-tirone-levels: '\e993';
$bitso-trash: '\e995';
$bitso-i_comment1: '\e955';
$bitso-i_heart1: '\e95f';
$bitso-i_elliotw1: '\e94e';
$bitso-i_fibb1: '\e960';
$bitso-i_hodl1: '\e962';
$bitso-download-img1: '\e967';
$bitso-undo1: '\e944';
$bitso-redo1: '\e96c';
$bitso-cursor1: '\e93c';
$bitso-annotation1: '\e91a';
$bitso-arc-speed-resistence1: '\e91c';
$bitso-arrow1: '\e91e';
$bitso-callout1: '\e920';
$bitso-crosshair1: '\e922';
$bitso-elipse1: '\e924';
$bitso-fib-arc1: '\e926';
$bitso-fib-fan1: '\e928';
$bitso-fib-projection1: '\e92a';
$bitso-fib-retracement1: '\e92c';
$bitso-fib-timeline1: '\e92e';
$bitso-focus1: '\e930';
$bitso-gann-fan1: '\e932';
$bitso-gartley-pattern1: '\e934';
$bitso-hide1: '\e936';
$bitso-line-average1: '\e938';
$bitso-line-channel1: '\e93a';
$bitso-line-continuous1: '\e976';
$bitso-line-crossline1: '\e978';
$bitso-line-doodle1: '\e97a';
$bitso-line-horizontal1: '\e97c';
$bitso-line-ray1: '\e97e';
$bitso-line-regression1: '\e980';
$bitso-line-simple1: '\e982';
$bitso-line-speed-resistence1: '\e984';
$bitso-line-vertical1: '\e986';
$bitso-measure1: '\e988';
$bitso-pitchfork1: '\e98a';
$bitso-quadrant-lines1: '\e98c';
$bitso-rectangle1: '\e98e';
$bitso-share1: '\e990';
$bitso-show1: '\e992';
$bitso-tirone-levels1: '\e994';
$bitso-trash1: '\e996';
$bitso-close: '\e900';
$bitso-hamburger-menu: '\e951';
$bitso-notif-trade: '\e93d';
$bitso-notif-fund: '\e93f';
$bitso-arrow-send: '\e941';
$bitso-notif-sell: '\e96d';
$bitso-notif-buy: '\e96f';
$bitso-order-partial: '\e971';
$bitso-order-open: '\e973';
$bitso-paperwork: '\e90c';
$bitso-world: '\e912';
$bitso-transfer: '\e918';
$bitso-zrx: '\e902';
$bitso-eye: '\e904';
$bitso-mana: '\e906';
$bitso-bat: '\e908';
$bitso-no-camera: '\e90a';
$bitso-chevron-right: '\e90e';
$bitso-warning: '\e910';
$bitso-check: '\e914';
$bitso-tusd: '\e916';
$bitso-gnt: '\e998';
$bitso-pesos: '\e99a';
$bitso-ltc: '\e99c';
$bitso-bch: '\e99e';
$bitso-xrp: '\e9a0';
$bitso-eth: '\e9a2';
$bitso-btc: '\e9aa';
$bitso-bank: '\e9ae';
$bitso-cash: '\e9b2';
$bitso-mobile: '\e9b0';
$bitso-global: '\e9a4';
$bitso-sad-face: '\e9a6';
$bitso-mail: '\e9a8';
$bitso-dai: '\e9ac';
$bitso-dai1: '\e9ad';
$bitso-close1: '\e901';
$bitso-hamburger-menu1: '\e95e';
$bitso-notif-trade1: '\e93e';
$bitso-notif-fund1: '\e940';
$bitso-arrow-send1: '\e942';
$bitso-notif-sell1: '\e96e';
$bitso-notif-buy1: '\e970';
$bitso-order-partial1: '\e972';
$bitso-order-open1: '\e974';
$bitso-paperwork1: '\e90d';
$bitso-world1: '\e913';
$bitso-transfer1: '\e997';
$bitso-zrx1: '\e903';
$bitso-eye1: '\e905';
$bitso-mana1: '\e907';
$bitso-bat1: '\e909';
$bitso-no-camera1: '\e90b';
$bitso-chevron-right1: '\e90f';
$bitso-warning1: '\e911';
$bitso-check1: '\e915';
$bitso-tusd1: '\e917';
$bitso-gnt1: '\e999';
$bitso-pesos1: '\e99b';
$bitso-ltc1: '\e99d';
$bitso-bch1: '\e99f';
$bitso-xrp1: '\e9a1';
$bitso-eth1: '\e9a3';
$bitso-btc1: '\e9ab';
$bitso-bank1: '\e9af';
$bitso-cash1: '\e9b3';
$bitso-mobile1: '\e9b1';
$bitso-global1: '\e9a5';
$bitso-sad-face1: '\e9a7';
$bitso-mail1: '\e9a9';
$bitso-download: '\e945';
$bitso-facebook: '\e947';
$bitso-twitter: '\e969';
$bitso-download1: '\e946';
$bitso-facebook1: '\e968';
$bitso-twitter1: '\e96a';
